<template scoped>
  <div class="designtool-arrow">
    <div class="designtool-arrow-line"></div>
    <div class="designtool-arrow-right"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.designtool-arrow {
  width: 100px;
  height: 117px;
}

.designtool\-arrow\-line {
  display: inline-block;
  height: 2px;
  background: #000;
  margin: 56.5px 0px 56.5px 10px;
  width: 50px;
}

.designtool\-arrow\-right {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #000;
  margin: 38px 0px;
}
</style>